import { useAppSelector } from 'hooks/useReduxHooks';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { routes } from 'routes';
import { IGlobalState } from 'store';

function Redirect() {
	const { nationality, passportNumber } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const location = useLocation();
	const pathName = location.pathname;
	const history = useHistory();
	const isLogin = localStorage.getItem('login');

	// 로그인이 안되어 있거나, routes의 포함되지 않는 Url 입력시 기본 Url 이동
	useEffect(() => {
		window.addEventListener('beforeunload', () => {
			localStorage.clear();
		});

		if (history.location.pathname.includes('/medical')) {
			const redirectUrl = 'https://meditour.ktaxpay.com/';
			window.location.href = redirectUrl;
			return;
		}

		if (
			(!Object.values(routes).includes(pathName) ||
				!isLogin ||
				!passportNumber ||
				!nationality) &&
			!pathName.includes('/email-verification')
		) {
			history.push('/');
		}
	}, []);
	return null;
}

export default Redirect;
