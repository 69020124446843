import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router';
import { routes } from 'routes';
import { IGlobalState } from 'store';
import styled from 'styled-components';
import { AxiosError } from 'axios';

import { getRefundInfo, registerRefundInfo } from 'api';
import BankInfoForm from 'components/Receipt/BankInfoForm';
import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import RefundInfoForm from 'components/Receipt/RefundInfoForm';
import FlexWrap from 'components/_common/FlexWrap';
import LoadingView from 'components/_common/LoadingView';
import TextButton from 'components/_common/TextButton';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IRefundInfoForm, IRegisterRefundInfoResponse } from 'model/types';
import Input from 'components/_common/Input';
import { IAddressTranslation } from 'types/ktp';
import { tanslateChFromToEnAddress } from 'utils/translate';
import CashPickupGuideButton from 'components/CustomerRefund/CashPickupGuideButton';

function RefundInfo() {
	const [selectedTransfer, setSelectedTransfer] = useState('');
	const [selectedAccountInfo, setSelectedAccountInfo] = useState('');
	const [selectedBank, setSelectedBank] = useState('');
	const [selectedAccountType, setSelectedAccountType] = useState('');
	const [selectedRegion, setSelectedRegion] = useState('');
	const [selectedTown, setSelectedTown] = useState('');
	const [isEditMode, setIsEditMode] = useState(false);
	const [selectedGender, setSelectedGender] = useState('');
	const [isBankCardInquiry, setIsBankCardInquiry] = useState(false);
	const [isHkgNation, setIsHkgNation] = useState(false);

	const history = useHistory();
	const { passportNumber, nationality, email } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const {
		handleSubmit,
		register,
		getValues,
		watch,
		setValue,
		formState: { errors },
	} = useForm<IRefundInfoForm>();
	const { firstName, lastName, phoneNumber, bank } = watch();
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (nationality === 'CHN' || nationality === 'HKG') {
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					nationality: isHkgNation ? 'HKG' : 'CHN',
				},
			});
		}
	}, [isHkgNation]);

	useEffect(() => {
		if (!isEditMode) return;
		setValue('aliPayAccount', '');
		setValue('cardNumber', '');
		setValue('bank', '');
		setValue('bankAccount', '');
		setValue('accountInfo', '');
		setValue('routingNumber', '');
		setValue('city', '');
		setValue('stateProvinceRegion', '');
		setValue('zipPostalCode', '');
		setValue('address', '');
		setValue('branchInformation', '');
		setValue('bsbNumber', '');
		setValue('transitNumber', '');
		setValue('cashPickup', '');
		setValue('ibanNumber', '');
		setValue('swiftCode', '');
		setValue('birth', '');
		setValue('addressDetail', '');
		setValue('addressDetail2', '');
		setValue('accountType', '');
		setValue('institutionNumber', '');
		setValue('gender', '');
		setValue('citizenId', '');
		setValue('firstNameEng', '');
		setValue('lastNameEng', '');
		setValue('country', '');
		setValue('accountHolder', '');
		setValue('stateProvinceRegionEng', '');
		setValue('cityEng', '');
		setValue('addressEng', '');
		setValue('addressDetailEng', '');
	}, [selectedTransfer]);

	useQuery<any, string>('RefundInfo', () => getRefundInfo(passportNumber), {
		retry: false,
		onSuccess: async (data) => {
			const { accountInfo } = data;
			setValue('firstName', accountInfo.firstName);
			setValue('lastName', accountInfo.lastName);
			setValue('phoneNumber', accountInfo.phoneNumber);
			setValue('transferMethod', accountInfo.transferMethod);
			setValue('aliPayAccount', accountInfo.aliPayAccount);
			setValue('cardNumber', accountInfo.cardNumber);
			setValue('bank', accountInfo.bank);
			setValue('bankAccount', accountInfo.bankAccount);
			setValue('accountInfo', accountInfo.accountInfo);
			setValue('routingNumber', accountInfo.routingNumber);
			setValue('stateProvinceRegion', accountInfo.stateProvinceRegion);
			setValue('zipPostalCode', accountInfo.zipPostalCode);
			setValue('address', accountInfo.address);
			setValue('branchInformation', accountInfo.branchInformation);
			setValue('bsbNumber', accountInfo.bsbNumber);
			setValue('transitNumber', accountInfo.transitNumber);
			setValue('cashPickup', accountInfo.cashPickup);
			setValue('ibanNumber', accountInfo.ibanNumber);
			setValue('swiftCode', accountInfo.swiftCode);
			setValue('birth', accountInfo.birth);
			setValue('addressDetail', accountInfo.addressDetail);
			setValue('addressDetail2', accountInfo.addressDetail2);
			setValue('accountType', accountInfo.accountType);
			setValue('institutionNumber', accountInfo.institutionNumber);
			setValue('country', accountInfo.country);
			setValue('gender', accountInfo.gender);
			setValue('citizenId', accountInfo.citizenId);
			setValue('firstNameEng', accountInfo.firstNameEng);
			setValue('lastNameEng', accountInfo.lastNameEng);
			setValue('directInput', accountInfo.directInput);
			setValue('accountHolder', accountInfo.accountHolder);
			setValue('stateProvinceRegionEng', accountInfo.stateProvinceRegionEng);
			setValue('cityEng', accountInfo.cityEng);
			setValue('addressEng', accountInfo.addressEng);
			setValue('addressDetailEng', accountInfo.addressDetailEng);
			setSelectedTransfer(accountInfo.transferMethod);
			setSelectedAccountInfo(accountInfo.accountInfo);
			setSelectedAccountType(accountInfo.accountType);
			setSelectedBank(accountInfo.bank);
			setSelectedGender(accountInfo.gender);
			setIsBankCardInquiry(accountInfo.isBankCardInquiry || true);
			setIsHkgNation(accountInfo.isHkgNation || false);
			await setSelectedRegion(accountInfo.stateProvinceRegion);
			setValue('city', accountInfo.city);
			setSelectedTown(accountInfo.city);
			if (nationality === 'TWN') {
				setSelectedBank(accountInfo.cashPickup);
			}
		},
	});
	const { isLoading, mutate: updateMutation } = useMutation<
		IRegisterRefundInfoResponse,
		AxiosError,
		any
	>((payload) => registerRefundInfo(payload), {
		retry: false,
		onSuccess: () => {
			alert('Refund information\nhas been modified.');
			history.push(routes.home);
		},
	});

	const onSubmit = async () => {
		const isChineseNationality = nationality === 'CHN';

		let enAddress: IAddressTranslation = {
			stateProvinceRegionEng: '',
			cityEng: '',
			addressEng: '',
			addressDetailEng: '',
		};

		if (isChineseNationality) {
			const { stateProvinceRegion, city, address, addressDetail } = getValues();

			enAddress = (await tanslateChFromToEnAddress(
				stateProvinceRegion,
				city,
				address,
				addressDetail
			)) as IAddressTranslation;
		}

		updateMutation({
			passportNumber,
			accountInfo: {
				...getValues(),
				gender: selectedGender,
				accountType: selectedAccountType,
				accountInfo: selectedAccountInfo,
				isBankCardInquiry,
				isHkgNation,
				stateProvinceRegionEng: enAddress.stateProvinceRegionEng,
				cityEng: enAddress.cityEng,
				addressEng: enAddress.addressEng,
				addressDetailEng: enAddress.addressDetailEng,
			},
			register: true,
			email,
		});
	};

	return (
		<ContainerWrapper className="notranslate">
			<ReceiptLayout>
				{isLoading && (
					<LoadingContainer>
						<LoadingView />
					</LoadingContainer>
				)}
				<Title>
					{nationality === 'KOR'
						? `${passportNumber}님의\n송금 정보를 한 번 더 확인해주세요`
						: 'Refund information'}
				</Title>
				<Wrapper>
					<RefundInfoForm
						register={register}
						getValues={getValues}
						setValue={setValue}
						errors={errors}
						disabled={!isEditMode}
						bankName={bank}
					/>
					<Input
						value={email}
						label="Email"
						isRequired
						disabled
						placeholder="Email"
						margin={[0, 0, 24]}
					/>
					<BankInfoForm
						register={register}
						getValues={getValues}
						errors={errors}
						disabled={!isEditMode}
						selectedAccountInfo={selectedAccountInfo}
						setSelectedAccountInfo={setSelectedAccountInfo}
						selectedBank={selectedBank}
						setSelectedBank={setSelectedBank}
						selectedAccountType={selectedAccountType}
						setSelectedAccountType={setSelectedAccountType}
						setValue={setValue}
						selectedGender={selectedGender}
						setSelectedGender={setSelectedGender}
						isBankCardInquiry={isBankCardInquiry}
						setIsBankCardInquiry={setIsBankCardInquiry}
						selectedRegion={selectedRegion}
						setSelectedRegion={setSelectedRegion}
						selectedTown={selectedTown}
						setSelectedTown={setSelectedTown}
						isHkgNation={isHkgNation}
						isRefundInfoPage
					/>
					{nationality === 'TWN' && (
						<CashPickupWrapper>
							<CashPickupGuideButton />
						</CashPickupWrapper>
					)}
				</Wrapper>
				<ButtonWrapper justifyContent="flex-end">
					{!isEditMode && (
						<TextButton
							title={nationality === 'KOR' ? '수정' : 'EDIT'}
							onClick={() => setIsEditMode(true)}
							isActive
						/>
					)}
					{isEditMode && (
						<TextButton
							title={nationality === 'KOR' ? '확인' : 'OK'}
							isActive
							disabled={
								!Boolean(firstName && lastName && phoneNumber) ||
								(nationality === 'CHN' && !isBankCardInquiry)
							}
							onClick={handleSubmit(onSubmit)}
							bgColor={
								!Boolean(firstName && lastName && phoneNumber)
									? '#E9F0FE'
									: '#246cf6'
							}
							color={
								!Boolean(firstName && lastName && phoneNumber)
									? '#1856CD'
									: '#E9F0FE'
							}
						/>
					)}
				</ButtonWrapper>
			</ReceiptLayout>
		</ContainerWrapper>
	);
}
const ContainerWrapper = styled.div``;
const Title = styled.h2`
	width: 100%;
	font-size: 21px;
	line-height: 150%;
	margin: 30px 0 20px;
	text-align: left;
	color: #1856cd;
	white-space: pre-wrap;
`;
const Wrapper = styled.div`
	width: 335px;
	margin-bottom: 30px;
	overflow: auto;
	padding-bottom: 58px;
	@media ${(props) => props.theme.mobile} {
		width: 100%;
	}
`;
const ButtonWrapper = styled(FlexWrap)`
	width: 100%;
	gap: 20px;
	padding: 0 20px;
	position: absolute;
	bottom: 20px;
	@media ${(props) => props.theme.mobile} {
		position: fixed;
	}
`;
const LoadingContainer = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.6);
`;
const CashPickupWrapper = styled.div`
	margin-bottom: 40px;
`;
export default RefundInfo;
