import { useHistory } from 'react-router';
import { routes } from 'routes';
import styled, { css, keyframes } from 'styled-components';

import FlexWrap from 'components/_common/FlexWrap';
import { getStatusData } from 'constant';
import { useAppSelector } from 'hooks/useReduxHooks';
import ProgressBar from './ProgressBar';
import { IGlobalState } from 'store';
import CashPickupGuideButton from 'components/CustomerRefund/CashPickupGuideButton';
import BankInquiryGuideButton from 'components/RefundResult/BankInquiryGuideButton';

function CurrentStatus() {
	const {
		isRefundInfoRegistered,
		paymentStatus,
		unapprovedCount,
		isUploadRequired,
		isReceiptImageUploaded,
		refundAfter,
		nationality,
	} = useAppSelector((state) => state) as IGlobalState;

	const { title, description, image } = getStatusData({
		paymentStatus,
		unapprovedCount,
		isRefundInfoRegistered,
		isUploadRequired,
		isReceiptImageUploaded,
		nationality,
	});
	const history = useHistory();

	const onClickActionBtn = () => {
		if (isUploadRequired) {
			//4009재외국민
			Boolean(isRefundInfoRegistered && isReceiptImageUploaded)
				? history.push(routes.refundInfo) //3단계(송금 전)
				: isRefundInfoRegistered
				? window.scrollTo({ behavior: 'smooth', top: 400 }) //2단계(전표 이미지 업로드 전)
				: history.push(routes.customerRefund); //1단계(송금정보 입력 전)
		} else {
			//외국인
			Boolean(isRefundInfoRegistered && unapprovedCount === 0)
				? history.push(routes.refundInfo) //4단계(송금 전)
				: Boolean(isRefundInfoRegistered && unapprovedCount !== 0)
				? window.scrollTo({ behavior: 'smooth', top: 900 }) //3단계(세관 방문 전)
				: isRefundInfoRegistered
				? history.push(routes.refundProcedure) //2단계(공항 와이파이 연결 전)
				: history.push(routes.customerRefund); //1단계(송금정보 입력 전)
		}
	};

	return (
		<Container
			refundAfter={refundAfter}
			isRefundInfoRegistered={isRefundInfoRegistered}
			onClick={(e) => {
				if (
					// @ts-ignore
					e.target.id !== 'guideButton' &&
					paymentStatus !== 'PAYMENT_COMPLETE'
				) {
					onClickActionBtn();
				} else {
					e.preventDefault();
				}
			}}
		>
			{refundAfter && <ProgressBar />}
			<FlexWrap
				gap="9px"
				justifyContent="space-between"
				style={{ width: '100%', marginTop: 24 }}
			>
				<FlexWrap dir="column">
					<Title>
						{refundAfter
							? title
							: nationality === 'KOR'
							? '텍스 프리 영수증입니다.'
							: 'Tax Refund Receipts'}
					</Title>
					<Description>
						{refundAfter
							? description
							: nationality === 'KOR'
							? `텍스 프리 처리가 끝난 영수증입니다.`
							: `These are immediate tax-refund case.\nYou don't need to visit customs.`}
					</Description>
					{nationality === 'TWN' && <CashPickupGuideButton />}
					{(nationality === 'JPN' || nationality === 'CHN') && (
						<BankInquiryGuideButton />
					)}
				</FlexWrap>
				<StatusImg src={image} />
			</FlexWrap>
		</Container>
	);
}
const blinkAnimation = keyframes`
  0% {opacity:1;}
  50% {opacity:0.6;}
  100% {opacity:1;}
  `;
const Container = styled.div<{
	refundAfter: boolean;
	isRefundInfoRegistered: boolean;
}>`
	margin: 24px 0;
	padding: 20px;
	width: 100%;
	border-radius: 20px;
	box-shadow: 0px 0px 20px 0px rgba(174, 191, 204, 0.5);
	display: flex;
	flex-direction: column;
	align-items: center;
	cursor: pointer;
	animation: ${blinkAnimation} 1500ms infinite;
	animation-play-state: ${(props) =>
		props.isRefundInfoRegistered ? 'paused' : 'running'};
	${(props) =>
		!props.refundAfter &&
		css`
			margin-top: 40px;
		`}
`;

const Title = styled.h3`
	font-size: 18px;
	line-height: 150%;
	font-weight: 700;
	color: #1856cd;
	white-space: pre-wrap;
`;
const Description = styled.p`
	white-space: pre-wrap;
	font-size: 13px;
	line-height: 150%;
	color: #3a3b3e;
	margin: 8px 0 28px;
`;
const StatusImg = styled.img`
	width: 50px;
	height: 50px;
`;
export default CurrentStatus;
