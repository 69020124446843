import axios from 'axios';
import { axiosInstance, axiosRequestConfiguration } from './config';
import {
	IFetchUserInfoPayload,
	IRegisterRefundInfoPayload,
	IReceiptPayload,
	IVerifyPassportPayload,
	ReceiptResponseType,
	IUpdateDepartureInfoPayload,
	IUploadImagePayload,
	IGetRefundListPayload,
	IGetRefundListResponse,
	IVerifyPassportResponse,
	IFetchUserInfoResponse,
	IDeleteImagePayload,
	IEmailSendPayload,
	ISendVerificationMailPayload,
	IVerifyEmailPayload,
	IRegisterRefundInfoResponse,
	ISendInquiryMailPayload,
	ISendInquiryPayload,
	IMyQna,
} from 'model/types';

// const axiosInstance = axios.create(axiosRequestConfiguration);

const KTP_SERVER =
	process.env.NODE_ENV === 'development'
		? 'http://123.142.246.2:50921'
		: 'https://admin.ktaxpay.com';
const ERP_SERVER = 'https://erp.successmode.co.kr';

const getAxios = async <ResponseType, ParamType>(
	url: string,
	queryParams?: ParamType,
	config?: any
) =>
	axiosInstance.get<ResponseType>(url, {
		...(queryParams && { params: queryParams }),
		...config,
	});
const postAxios = async <ReturnType, BodyType, ParamType>(
	url: string,
	body: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.post<ReturnType>(url, body, {
		...(queryParams && { params: queryParams }),
	});
const patchAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.patch<ResponseType>(url, body, {
		...(queryParams && { params: queryParams }),
	});
const putAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.put<ResponseType>(url, body, {
		...(queryParams && { params: queryParams }),
	});

const deleteAxios = async <ResponseType, BodyType, ParamType>(
	url: string,
	body?: BodyType,
	queryParams?: ParamType
) =>
	axiosInstance.delete<ResponseType>(url, {
		...(body && { data: body }),
		...(queryParams && { params: queryParams }),
	});

//================== KTP ================================================
//홈 조회
export const getHome = async (payload: IVerifyPassportPayload) => {
	const res = await postAxios<
		IVerifyPassportResponse,
		IVerifyPassportPayload,
		null
	>(`${KTP_SERVER}/refund/receipt/home`, payload);
	return res.data;
};
//여권 인증
export const verifyPassport = async (payload: IVerifyPassportPayload) => {
	const res = await postAxios<
		IVerifyPassportResponse,
		IVerifyPassportPayload,
		null
	>(`${KTP_SERVER}/refund/receipt/passport-validate`, payload);
	return res.data;
};
//출국 정보 업데이트
export const updateDepartureInfo = async (
	payload: IUpdateDepartureInfoPayload
) => {
	const res = await putAxios(`${KTP_SERVER}/refund/receipt/departure`, payload);
	return res.data;
};
//전표 생성용 데이터 조회
export const getReceipt = async (payload: IReceiptPayload) => {
	const res = await postAxios<ReceiptResponseType, IReceiptPayload, null>(
		`${KTP_SERVER}/refund/receipt`,
		payload
	);
	return res.data;
};
//환급 내역 조회
export const getRefundList = async (payload: IGetRefundListPayload) => {
	const res = await postAxios<
		IGetRefundListResponse,
		IGetRefundListPayload,
		null
	>(`${KTP_SERVER}/refund/receipt/list`, payload);
	return res.data;
};
//4009 재외국민 영수증 이미지 업로드
export const uploadImage = async ({
	refundIndex,
	formData,
}: IUploadImagePayload) => {
	const res = await postAxios<string, FormData, null>(
		`${KTP_SERVER}/refund/receipt/upload/${refundIndex}`,
		formData
	);
	return res.data;
};
//4009 재외국민 영수증 이미지 재업로드
export const updateImage = async ({
	refundIndex,
	formData,
}: IUploadImagePayload) => {
	const res = await patchAxios<string, FormData, null>(
		`${KTP_SERVER}/refund/receipt/upload/${refundIndex}`,
		formData
	);
	return res.data;
};
//4009 재외국민 영수증 이미지 제거
export const deleteImage = async ({ refundIndex }: IDeleteImagePayload) => {
	const res = await deleteAxios<number, null, null>(
		`${KTP_SERVER}/refund/receipt/upload/${refundIndex}`
	);
	return res.status;
};
//송금 정보 입력
export const registerRefundInfo = async ({
	isForTemporaryStorage,
	...payload
}: IRegisterRefundInfoPayload) => {
	await postAxios<
		IRegisterRefundInfoResponse,
		IRegisterRefundInfoPayload,
		null
	>(`${KTP_SERVER}/refund/receipt/account/`, payload);
	return { isForTemporaryStorage: isForTemporaryStorage };
};
//검증 메일 전송
export const sendVerificationMail = async (
	payload: ISendVerificationMailPayload
) => {
	const res = await postAxios<number, ISendVerificationMailPayload, null>(
		`${KTP_SERVER}/mail/refund/receipt`,
		payload
	);
	return res.status;
};
//이메일 검증
export const verifyEmail = async (payload: IVerifyEmailPayload) => {
	const res = await patchAxios<number, IVerifyEmailPayload, null>(
		`${KTP_SERVER}/refund/receipt/mail`,
		payload
	);
	return res.status;
};

//문의 메일 전송
export const sendInquiryMail = async (payload: ISendInquiryMailPayload) => {
	const res = await postAxios<number, ISendInquiryMailPayload, null>(
		`${KTP_SERVER}/mail/inquiries`,
		payload
	);
	return res.status;
};
//마이페이지 조회
export const fetchUserInfo = async (payload: IFetchUserInfoPayload) => {
	const res = await postAxios<
		IFetchUserInfoResponse,
		IFetchUserInfoPayload,
		null
	>(`${KTP_SERVER}/customer/my-page`, payload);
	return res.data;
};

//송금 정보 조회
export const getRefundInfo = async (passportNumber: string | null) => {
	const res = await getAxios<any, string>(
		`${KTP_SERVER}/refund/receipt/account/${passportNumber}`
	);
	return res.data;
};

//전표 메일 전송
export const sendReceiptMail = async (payload: FormData) => {
	const res = await postAxios<string, FormData, null>(
		`${KTP_SERVER}/mail/receipt`,
		payload
	);
	return res.data;
};

// 송금 정보 수정후 메일 전달
export const emailSend = async (payload: IEmailSendPayload) => {
	const res = await postAxios<string, IEmailSendPayload, null>(
		`${KTP_SERVER}/mail/customer_location`,
		payload
	);
	return res.data;
};
// 리플렛 고객 송금 정보 입력
export const getRefundInfoData = async (payload: FormData) => {
	const res = await postAxios<string, FormData, null>(
		`${ERP_SERVER}/customer/fault/leaflet`,
		payload
	);
	return res.data;
};
//이메일 인증여부 확인
export const checkEmailAuth = async (payload: any) => {
	const res = await postAxios<any, any, null>(
		`${KTP_SERVER}/refund/receipt/mail-check`,
		payload
	);
	return res.data;
};
// 나의 문의 내역 조회
export const getMyQnaList = async (passportNumber: string) => {
	const res = await getAxios(`${KTP_SERVER}/question/${passportNumber}`);
	return res.data as IMyQna[];
};
// 문의하기
export const sendInquiry = async (payload: ISendInquiryPayload) => {
	const res = await postAxios(`${KTP_SERVER}/question`, payload);
	return res.status;
};
// 문의 답변 읽음처리
export const updateNewAnswer = async (passportNumber: string) => {
	const res = await patchAxios(`${KTP_SERVER}/question/${passportNumber}`);
	return res.data;
};
