import { Fragment, useEffect } from 'react';
import { AxiosError } from 'axios';
import { useHistory, useLocation } from 'react-router';
import { useMutation } from 'react-query';
import { getHome } from 'api';
import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import TopSection from 'components/Home/TopSection';
import BottomSection from 'components/Home/BottomSection';
import { IGlobalState } from 'store';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { getDday } from 'utils/common';
import { IVerifyPassportPayload, IVerifyPassportResponse } from 'model/types';
import LoadingView from 'components/_common/LoadingView';
import FaqAnswerModal from 'components/Faq/FaqAnswer.modal';

type Params = {
	scrollToReceipt?: boolean;
};
function Home() {
	const dispatch = useAppDispatch();
	const params = useLocation().state as Params;
	const scrollToReceipt = params?.scrollToReceipt;
	const history = useHistory();
	const {
		isUploadRequired,
		passportNumber,
		departureDate,
		refundAfter,
		nationality,
		hasNewAnswer,
	} = useAppSelector((state) => state) as IGlobalState;

	const onCloseModal = () => {
		dispatch({
			type: 'UPDATE_HOME_MODAL_STATE',
			payload: { isActive: false, data: null, type: null },
		});
	};

	const { mutate: homeMutation, isLoading } = useMutation<
		IVerifyPassportResponse,
		AxiosError,
		IVerifyPassportPayload
	>((payload) => getHome(payload), {
		retry: false,
		onSuccess: ({
			register,
			read,
			receiptUpload,
			departureStatus,
			paymentStatus,
			approvedCount,
			unapprovedCount,
			refundAmount,
			refundAfterAmount,
			email,
			refundCondition,
			maximumTransferDate,
			departureDate,
			hasNewAnswer,
			customerNation,
		}) => {
			dispatch({
				type: 'UPDATE_USER_INFO',
				payload: {
					isRefundInfoRegistered: register,
					haveSeenReceipt: read,
					isReceiptImageUploaded: receiptUpload,
					isCustomerLeft: isUploadRequired
						? getDday(departureDate as string) === 'D-DAY'
						: departureStatus === 'DEPARTURE_COMPLETE',
					paymentStatus,
					approvedCount,
					unapprovedCount,
					refundAmount,
					refundAfterAmount,
					email,
					refundCondition,
					maximumTransferDate,
					departureDate,
					hasNewAnswer,
					nationality: customerNation,
				},
			});
		},
	});
	const fetchHome = () => {
		homeMutation({ passportNumber: passportNumber as string });
	};

	useEffect(() => {
		fetchHome();
	}, [passportNumber]);

	useEffect(() => {
		const preventGoBack = () => {
			onCloseModal();
			history.go(1);
		};
		window.addEventListener('popstate', preventGoBack);
		return () => window.removeEventListener('popstate', preventGoBack);
	}, []);

	return (
		<ReceiptLayout hasPadding={false} style={{ paddingBottom: 56 }}>
			{isLoading ? (
				<LoadingView />
			) : (
				<Fragment>
					<TopSection />
					{refundAfter && (
						<BottomSection
							refetchHome={fetchHome}
							scrollToReceipt={scrollToReceipt}
						/>
					)}
				</Fragment>
			)}
			{hasNewAnswer && <FaqAnswerModal />}
		</ReceiptLayout>
	);
}
export default Home;
