import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';

export const axiosRequestConfiguration: AxiosRequestConfig = {
	baseURL: 'https://admin.successmode.co.kr:20921',
	responseType: 'json',
	headers: {
		Authorization: 'successmode806',
	},
};

export const axiosInstance = axios.create(axiosRequestConfiguration);

axiosInstance.interceptors.response.use(
	(res: AxiosResponse) => {
		return res;
	},
	async ({ response }: AxiosError) => {
		const code = response?.data.code;
		switch (code) {
			case 'Unknown': {
				alert(
					'An unknown error occurred. Please refresh and try again in a few minutes.'
				);
				break;
			}
			case undefined: {
				alert(
					'The network condition is unstable. Please refresh and try again in a few minutes.'
				);
				break;
			}
			default: {
				break;
			}
		}
		return Promise.reject(response);
	}
);
