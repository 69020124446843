import React, { useState } from 'react';
import { Calendar } from 'react-calendar';
import { useMutation } from 'react-query';
import { format } from 'date-fns';
import styled from 'styled-components';

import { updateDepartureInfo } from 'api';
import Close from 'assets/common/close.png';
import FlexWrap from 'components/_common/FlexWrap';
import TextButton from 'components/_common/TextButton';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { IUpdateDepartureInfoPayload } from 'model/types';

type Props = {
	onClose: () => void;
};
function CalendarModal({ onClose }: Props) {
	const today = new Date();
	const dispatch = useAppDispatch();
	const {
		departureDate: prevDepartureDate,
		nationality,
		passportNumber,
		email,
		firstRefundDate,
	} = useAppSelector((state) => state) as IGlobalState;
	const [departureDate, setDepartureDate] = useState(
		prevDepartureDate as string
	);

	const departureMutation = useMutation(
		['deaprtureDate'],
		(payload: IUpdateDepartureInfoPayload) => updateDepartureInfo(payload),
		{
			onSuccess: () => {
				dispatch({
					type: 'UPDATE_USER_INFO',
					payload: { departureDate },
				});
			},
		}
	);
	const onUpdateDepartureDate = () => {
		departureMutation.mutate({
			passportNumber,
			email,
			departureDate: format(new Date(departureDate), 'yyyy-MM-dd'),
		});
		onClose();
	};

	return (
		<BackWrapper>
			<BackContainer>
				<ModalContainer>
					<CloseBtn src={Close} onClick={onClose} />
					<Calendar
						//@ts-ignore
						onChange={setDepartureDate}
						value={new Date(departureDate as string)}
						formatDay={(_locale, date) => format(date, 'dd')}
						showNeighboringMonth={false}
						maxDetail="month"
						minDetail="month"
						next2Label={null}
						prev2Label={null}
						calendarType="US"
						minDate={new Date(firstRefundDate || '')}
					/>
					<Wrapper dir="column">
						<Title>
							{nationality === 'KOR'
								? '출국예정일 주의사항'
								: 'Expected Departure Date Precautions'}
						</Title>
						<SubTitle>
							{nationality === 'KOR' ? '① 환급대상' : '① Eligible shoppers'}
						</SubTitle>
						<Desc>
							{nationality === 'KOR'
								? `환급대상 : 입국일로부터 3개월 이내 출국자\n출국일은 본인의 입국일로부터 3개월이 초과되지 않도록 지정해주세요.`
								: 'Leave the country within three months from the purchase date, and meet the following qualifications: Foreigner (Stayed less than six months in Korea)'}
						</Desc>
						<SubTitle>
							{nationality === 'KOR'
								? '② 출국예정일 수정'
								: '② Modifying the departure date.'}
						</SubTitle>
						<Desc>
							{nationality === 'KOR'
								? ' 현재 날짜기준으로 지난 일은 선택이 불가합니다. 수정은 출국일 최소 1~3일 전에 수정 바랍니다.'
								: 'You cannot select the past date based on the current date. Please correct it at least 1 days before the departure date'}
						</Desc>
						<TextButton
							title={nationality === 'KOR' ? '선택' : 'CHECK'}
							onClick={onUpdateDepartureDate}
							width="100%"
							margin="36px 0 16px"
							bgColor="#246CF6"
						/>
						<TextButton
							title={nationality === 'KOR' ? '취소' : 'CANCEL'}
							onClick={onClose}
							outline
							bgColor="#ffffff"
							color="#000000"
							width="100%"
							margin="0 0 20px"
						/>
					</Wrapper>
				</ModalContainer>
			</BackContainer>{' '}
		</BackWrapper>
	);
}
const BackWrapper = styled.div`
	width: 100vw;
	display: flex;
	justify-content: center;
`;
const BackContainer = styled(FlexWrap)`
	display: flex;
	justify-content: center;
	position: fixed;
	top: 0;
	width: 375px;
	height: 100%;
	z-index: 500;
	background-color: rgba(0, 0, 0, 0.4);
	@media ${(props) => props.theme.mobile} {
		width: 100%;
	}
`;
const ModalContainer = styled.div`
	position: relative;
	top: 10%;
	width: 335px;
	height: 790px;
	background-color: #ffffff;
	padding: 60px 0;
	overflow-y: scroll;
	border-radius: 4px;
	@media ${(props) => props.theme.mobile} {
		top: 5%;
		height: 90%;
	}
`;
const CloseBtn = styled.img`
	position: absolute;
	top: 30px;
	right: 20px;
	width: 18px;
	height: 18px;
	cursor: pointer;
`;
const Wrapper = styled(FlexWrap)`
	padding: 0 20px;
`;
const Title = styled.h3`
	font-size: 16px;
	font-weight: 500;
	margin-top: 24px;
`;
const SubTitle = styled.h4`
	font-size: 14px;
	line-height: 20px;
	margin: 12px 0 6px;
`;
const Desc = styled.p`
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
	white-space: pre-wrap;
`;
export default CalendarModal;
