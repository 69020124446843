export const routes = {
	// KTP
	passport: "/",
	home: "/home",
	receiptInquiry: "/receipt",
	customerRefund: "/refund",
	refundResult: "/refund-result",
	myPage: "/mypage",
	refundProcedure: "/refund-procedure",
	refundInfo: "/refund-info",
	refundInfoLeaflet: "/refund-info-leaflet",
	faq: "/faq",
	// Medical
	medical: "/medical",
};
