import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router';
import { routes } from 'routes';
import WhiteLogoImg from 'assets/common/ktp_logo_white.png';
import LogoImg from 'assets/common/ktp_logo.png';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

interface IProps {
	isTop: boolean;
	isMobile?: boolean;
}
function Logo({ isTop, isMobile }: IProps) {
	const { departureDate } = useAppSelector((state) => state) as IGlobalState;
	const { pathname } = useLocation();
	const history = useHistory();

	const onClick = () => {
		// 처음 화면
		if (pathname === '/' || pathname === '/medical') {
			return;
		}
		if (departureDate) {
			history.replace(routes.home);
		}
		window.scrollTo(0, 0);
	};
	return (
		<LogoWrapper isMobile={isMobile}>
			<SLogo
				alt="Korea Tax Free Payments"
				src={isTop ? WhiteLogoImg : LogoImg}
				onClick={onClick}
			/>
		</LogoWrapper>
	);
}

const LogoWrapper = styled.div<{ isMobile?: boolean }>`
	position: relative;
	width: 80px;
	height: 22px;
`;

const SLogo = styled.img`
	width: 100%;
	height: 100%;
	object-fit: cover;
	cursor: pointer;
`;
export default Logo;
