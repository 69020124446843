import { useState } from 'react';
import { Spin } from 'animation';
import { useAppSelector } from 'hooks/useReduxHooks';
import { useMutation, useQueryClient } from 'react-query';
import { IGlobalState } from 'store';
import styled from 'styled-components';
import { ISendInquiryPayload } from 'model/types';
import ModalFrame from 'components/_common/ModalFrame';
import FaqButton from './FaqButton';
import { sendInquiry } from 'api';

type Props = {
	onClose: () => void;
};

function FaqModal({ onClose }: Props) {
	const { passportNumber, nationality } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const [content, setContent] = useState('');
	const queryClient = useQueryClient();

	const mutation = useMutation(
		(payload: ISendInquiryPayload) => sendInquiry(payload),
		{
			retry: false,
			onSuccess: () => {
				setContent('');
				onClose();
				alert(
					nationality === 'KOR'
						? `문의가 완료되었습니다.\n\n답변까지 2~3일 정도 소요됩니다.\n빠른 시일 내로 답변 드리겠습니다.`
						: `You have sent an inquiry.\nPlease allow 2-3 days for a response.`
				);
				queryClient.invalidateQueries('getMyQnaList');
			},
			onError: () => {
				alert('메일 전송에 실패하였습니다.');
			},
		}
	);

	const sendQuestion = () => {
		if (content.length > 2000) {
			alert(
				nationality === 'KOR'
					? '문의 내용은 2000 자를 넘길 수 없습니다.'
					: 'Your inquiry cannot exceed 2000 characters.'
			);
		}
		mutation.mutate({
			passportNumber: passportNumber as string,
			content,
			fromKioskWeb: false,
		});
	};

	return (
		<ModalFrame
			title={nationality === 'KOR' ? 'KTP 1:1 문의' : 'KTP 1:1 Inquiry'}
			content={
				mutation.isLoading ? (
					<LoadingView>
						<LoadingSpinner />
					</LoadingView>
				) : (
					<FaqTextarea
						maxLength={2000}
						placeholder={
							nationality === 'KOR'
								? '서비스 이용 절차 등 궁금하신 사항을 남겨주세요. 문의 내용을 확인하여 빠르게 연락드리도록 하겠습니다.'
								: `Leave us a message with your questions. We'll read it and get back to you as soon as possible.`
						}
						value={content}
						onChange={(e) => setContent(e.target.value)}
					/>
				)
			}
			buttons={
				<>
					<FaqButton
						status="cancel"
						padding="10px 0"
						width="100%"
						fontSize={16}
						onClick={onClose}
					>
						{nationality === 'KOR' ? '취소' : 'Cancel'}
					</FaqButton>
					<FaqButton
						status="active"
						padding="10px 0"
						width="100%"
						fontSize={16}
						onClick={sendQuestion}
						disabled={content.length === 0}
					>
						{nationality === 'KOR' ? '전송' : 'Send'}
					</FaqButton>
				</>
			}
		/>
	);
}

export default FaqModal;

const LoadingView = styled.div`
	width: 100%;
	height: 156px;
	display: flex;
	align-items: center;
`;

const LoadingSpinner = styled.div`
	animation: ${Spin} 600ms linear infinite;
	border-radius: 50%;
	width: 28px;
	height: 28px;
	border: 5px solid #fff;
	margin: 0 auto;
	border-top: 5px solid #246cf6;
`;

const FaqTextarea = styled.textarea`
	border: none;
	border-radius: 8px;
	width: calc(100% + (100% * 0.1));
	height: 170px;
	padding: calc(16px * 0.9);
	font-size: 16px;
	transform: scale(0.9);
	transform-origin: left top;
	font-weight: 400;
	line-height: 150%;
	resize: none;
	outline: none;
	background-color: #f5f6f7;
	::placeholder {
		color: #5f6165;
	}
`;
