import { useHistory } from 'react-router';
import { routes } from 'routes';
import styled from 'styled-components';

import EnterRefundInfo from 'assets/refundProcedure/enter_refund_info.png';
import SendMoney from 'assets/refundProcedure/send_money.png';
import UploadReceipt from 'assets/refundProcedure/upload_receipt.png';
import VisitCustoms from 'assets/refundProcedure/visit_customs.png';
import VisitCustomsMobile from 'assets/refundProcedure/visit_customs_mobile.png';
import Airplane from 'assets/common/airplane.png';
import InnerLayout from 'components/_common/InnerLayout';
import TextButton from 'components/_common/TextButton';
import { useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';

function BottomSection() {
	const history = useHistory();
	const { nationality, isUploadRequired } = useAppSelector(
		(state) => state
	) as IGlobalState;

	const goToHome = () => {
		history.push(routes.home);
	};

	return (
		<Container>
			<Inner>
				<Title>
					{nationality === 'KOR' ? '텍스 리펀 받는 법' : 'Tax Refund Process'}
				</Title>
				<Content>
					<Indicator src={Airplane} />
					<Step>{nationality === 'KOR' ? '첫 번째' : 'STEP 1'}</Step>
					<SubTitle>
						{nationality === 'KOR'
							? '3개월 이내 환급정보 입력'
							: 'Enter information within 3 months'}
					</SubTitle>
					<ContentText>
						{nationality === 'KOR'
							? isUploadRequired
								? `KTP 텍스 리펀은 고객님의 계좌로 환급금을 돌려드립니다. 안내에 따라 정확한 정보를 입력해주세요.`
								: `KTP는 송금 택스 리펀으로 공항에서 시간을 절약하세요.\n송금 받을 계좌 정보를 정확히 입력해주세요.`
							: 'Save time at the airport with KTP. Please make sure to enter the correct information to receive your refund amount via transfer.'}
					</ContentText>
					<ContentImage src={EnterRefundInfo} />
					<Step>{nationality === 'KOR' ? '두 번째' : 'STEP 2'}</Step>
					{isUploadRequired ? (
						<>
							{/* 4009 한국인만 */}
							<SubTitle>영수증 출력 & 세관 방문</SubTitle>
							<ContentText>
								{`이메일로 전송 된 Tax Refund 영수증을 출력해주세요.\n출력한 영수증과 물품을 세관에 보여주고 도장을 받으세요.`}
							</ContentText>
							<ContentImage src={VisitCustoms} />
							<Step>세 번째</Step>
							<SubTitle>영수증 사진 등록</SubTitle>
							<ContentText>
								{`세관 도장이 찍힌 KTP Tax Refund 영수증을 올려주세요.\n영수증 전체가 잘 보이도록 촬영해주세요.`}
							</ContentText>
							<ContentImage src={UploadReceipt} />
						</>
					) : (
						<>
							{/* 외국인 */}
							<SubTitle>
								{nationality === 'KOR' ? '세관 방문' : 'Visit customs.'}
							</SubTitle>
							<ContentText>
								{nationality === 'KOR'
									? `키오스크에서 기다리지 말고 바로 세관으로 가세요.\n구매한 물건과 모바일 택스리펀 영수증을 보여주세요.`
									: `Go straight to customs with your [KTP mobile Tax Refund Receipt] instead of waiting at a kiosk.`}
							</ContentText>
							<ContentImage src={VisitCustomsMobile} />
						</>
					)}
					<Step>{nationality === 'KOR' ? '마지막' : 'STEP 3'}</Step>
					<SubTitle>
						{nationality === 'KOR' ? '환급액 송금' : 'Receive your refund'}
					</SubTitle>
					<ContentText>
						{nationality === 'KOR'
							? `계좌 입력 및 세관 확인이 완료되면 환급액을 송금합니다.\n환급액 지급은 세관 확인 후 영업일 기준 21일 정도 소요\n됩니다.`
							: `We'll send your refund amount to the refund\ninformation you provided. Refund will be\ncompleted about 21 business days.`}
					</ContentText>
					<ContentImage src={SendMoney} />
				</Content>
			</Inner>
			<BtnWrapper>
				<TextButton
					title={
						nationality === 'KOR' ? '지금 환급 받기' : 'Get Tax Refund Now'
					}
					hasArrow
					onClick={goToHome}
				/>
			</BtnWrapper>
		</Container>
	);
}
const Container = styled.div`
	position: relative;
	width: 100%;
	background-color: #fff;
	border-radius: 20px;
	-webkit-box-shadow: 0px 20px 20px -20px rgba(174, 191, 204, 0.5);
	box-shadow: 0px 0px 20px 0px rgba(174, 191, 204, 0.5);
`;
const Inner = styled(InnerLayout)`
	background-color: #fff;
`;
const Title = styled.h2`
	font-size: 24px;
	line-height: 150%;
	margin: 56px auto 44px 20px;
	font-weight: 700;
`;
const SubTitle = styled.h3`
	font-size: 18px;
	line-height: 150%;
	margin-bottom: 12px;
	font-weight: 700;
`;

const Content = styled.div`
	display: flex;
	flex-direction: column;
	padding: 0 20px 0 24px;
	margin-left: 20px;
	border-left: 4px solid #d9d9d9;
`;
const Step = styled.span`
	color: #fff;
	background-color: #246cf6;
	font-size: 14px;
	line-height: 21px;
	padding: 4px 0;
	text-align: center;
	border-radius: 100px;
	width: 80px;
	margin-bottom: 20px;
`;
const ContentText = styled.p`
	font-size: 13px;
	line-height: 150%;
	margin-bottom: 12px;
	white-space: pre-wrap;
`;
const ContentImage = styled.img`
	width: 100%;
	margin-bottom: 44px;
`;
const Indicator = styled.img`
	width: 20px;
	height: 20px;
	position: absolute;
	left: 12px;
	top: 150px;
`;
const BtnWrapper = styled.div`
	padding: 20px;
	background-color: #fff;
`;
export default BottomSection;
