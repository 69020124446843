import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { routes } from 'routes';
import styled from 'styled-components';

import CheckCircle from 'assets/customerRefund/check_circle.png';
import ReceiptLayout from 'components/Receipt/ReceiptLayout';
import TextButton from 'components/_common/TextButton';
import ProgressBar from 'components/_common/ProgressBar';
import SendingReceiptMail from 'components/_common/SendingReceiptMail';
import { useAppDispatch, useAppSelector } from 'hooks/useReduxHooks';
import { IGlobalState } from 'store';
import { getIsFrequentCountry } from 'utils/common';
import BankInquiryGuide from 'components/RefundResult/BankInquiryGuide';

type Params = {
	isEmailChanged?: boolean;
};
function RefundResult() {
	const history = useHistory();
	const dispatch = useAppDispatch();
	const { isEmailChanged } = useParams() as Params;
	const { nationality, isUploadRequired, refundCondition } = useAppSelector(
		(state) => state
	) as IGlobalState;
	const [sendingStatus, setSendingStatus] = useState<'IS_SENDING' | 'DEFAULT'>(
		'DEFAULT'
	);

	const goToRecipt = () => {
		if (refundCondition === 'REFUND_ONLY') {
			dispatch({ type: 'UPDATE_USER_INFO', payload: { refundAfter: false } });
			history.push(routes.home);
		} else {
			dispatch({ type: 'UPDATE_USER_INFO', payload: { refundAfter: true } });
			if (isUploadRequired) {
				history.push(routes.receiptInquiry);
			} else {
				history.push(routes.home, {
					scrollToReceipt: true,
				});
			}
		}
	};
	const onNavigate = (value: string) => {
		history.push(value);
	};

	useEffect(() => {
		isUploadRequired && isEmailChanged && setSendingStatus('IS_SENDING');
	}, []);

	useEffect(() => {
		dispatch({
			type: 'UPDATE_USER_INFO',
			payload: { isRefundInfoRegistered: true },
		});
	}, []);

	useEffect(() => {
		const handleBackButton = () => {
			history.push(routes.home);
		};
		window.addEventListener('popstate', handleBackButton);
		return () => {
			window.removeEventListener('popstate', handleBackButton);
		};
	}, []);

	return (
		<ReceiptLayout>
			{sendingStatus === 'IS_SENDING' && (
				<SendingReceiptMail setSendingStatus={setSendingStatus} />
			)}
			<ProgressBar percent={100} />
			<Img src={CheckCircle} />
			<Title>
				{nationality === 'KOR' ? `모든 정보를 입력했습니다.` : `Completed`}
			</Title>
			<Description>
				{nationality === 'KOR'
					? isUploadRequired
						? `Tax Refund 영수증을 출력하고\n세관 도장을 받으세요.`
						: `세관에 방문하여 구매한 물건과\n모바일 Tax Refund 영수증을 보여주세요.\n이후 텍스리펀까지 영업일 기준 평균 21일 가량 소요됩니다.`
					: getIsFrequentCountry(nationality)
					? 'Please show your purchases and your\nMobile tax refund receipt to customs.\nThen we’ll transfer your refund amount\nto the information you provided within 21 business days.'
					: "Please show your purchases and your\nMobile tax refund receipt to customs.\nThen we'll send an email to request your information\nfor processing refunds within 10 days.\nPlease check your mailbox."}
				{nationality === 'JPN' && (
					<BankInquiryGuide
						title={
							<>
								<InfoTitle>
									海外送金のために銀行から問い合わせが来ることもあります。
								</InfoTitle>
								<InfoTitle>そういう時はこう答えてください。</InfoTitle>
							</>
						}
						questionTitle="ぎんこう"
						questionText="Successmodeとはどのような関係ですか?"
						answerTitle="あなた"
						answerText='Successmodeは還付窓口運営事業者として 「TAX REFUND」を代行します。 (代表者 "Ju Byeong-Cheon" に入金されることがあります。)'
					/>
				)}
				{nationality === 'CHN' && (
					<BankInquiryGuide
						title={
							<>
								<InfoTitle>因为海外汇款，银行可能会来咨询。</InfoTitle>
								<InfoTitle>那种时候请这样回答。</InfoTitle>
							</>
						}
						questionTitle="银行"
						questionText="与Successmode是什么关系?"
						answerTitle="您"
						answerText='Successmode作为退税窗口事业者代理 Tax Refund。(代表人名"JuByeong-Cheon")'
					/>
				)}
			</Description>
			<TextButton
				title={
					nationality === 'KOR'
						? '내 Tax Refund 영수증 보기'
						: 'My Mobile Tax Refund Receipts'
				}
				color="#FFFFFF"
				bgColor="#246CF6"
				margin="0 0 20px"
				onClick={goToRecipt}
			/>
			<TextButton
				title={nationality === 'KOR' && isUploadRequired ? '홈으로' : 'Home'}
				color="#246CF6"
				bgColor="#FFFFFF"
				outline
				onClick={() => onNavigate(routes.home)}
			/>
		</ReceiptLayout>
	);
}

export default RefundResult;

const Img = styled.img`
	width: 66px;
	height: 66px;
	margin: 200px auto 30px;
	transform: translateX(-4px);
	@media ${(props) => props.theme.mobile} {
		margin: 130px auto 20px;
	}
`;

const Title = styled.h2`
	color: #1856cd;
	font-size: 28px;
	line-height: 36px;
	margin-bottom: 20px;
	font-weight: 700;
	width: 410px;
	text-align: center;
	white-space: pre-wrap;
`;

const Description = styled.p`
	font-size: 14px;
	font-weight: 500;
	color: #3a3b3e;
	line-height: 150%;
	white-space: pre-wrap;
	text-align: center;
	margin-bottom: 100px;
`;

const InfoTitle = styled.span`
	display: block;
	color: #246cf6;
	font-size: 12px;
	font-weight: 600;
	line-height: 18px;
`;
