import placeImg1 from 'assets/printGuide/case1_1.png';
import placeImg2 from 'assets/printGuide/case2_1.png';
import placeImg3 from 'assets/printGuide/case3_1.png';
import placeImg4 from 'assets/printGuide/case4_1.png';
import placeImg5 from 'assets/printGuide/case5_1.png';
import placeImg6 from 'assets/printGuide/case6_1.png';
import refundPrintImg1 from 'assets/printGuide/case1_2.png';
import refundPrintImg2 from 'assets/printGuide/case2_2.png';
import refundPrintImg3 from 'assets/printGuide/case3_2.png';
import refundPrintImg4 from 'assets/printGuide/case4_2.png';
import refundPrintImg5 from 'assets/printGuide/case5_2.png';
import refundPrintImg6 from 'assets/printGuide/case6_2.png';
import Incheon1 from 'assets/customsLocation/incheon_t1.png';
import Incheon2 from 'assets/customsLocation/incheon_t2.png';
import Gimpo from 'assets/customsLocation/gimpo.png';
import Gimhae from 'assets/customsLocation/gimhae.png';
import Jeju from 'assets/customsLocation/jeju.png';
import Incheon1Map from 'assets/customsLocation/incheon_t1_map.png';
import Incheon2Map from 'assets/customsLocation/incheon_t2_map.png';
import GimpoMap from 'assets/customsLocation/gimpo_map.png';
import GimhaeMap from 'assets/customsLocation/gimhae_map.png';
import JejuMap from 'assets/customsLocation/jeju_map.png';
import EnterRefundInfo from 'assets/home/enter_refund_info.png';
import Completed from 'assets/home/completed.png';
import SendingMoney from 'assets/home/sending_money.png';
import UploadReceipt from 'assets/home/upload_receipt.png';
import VisitCustoms from 'assets/home/visit_customs.png';
import { IGlobalState } from 'store';

export const bankList = [
	'없음',
	'국민은행',
	'기업은행',
	'농협은행',
	'신한은행',
	'산업은행',
	'우리은행',
	'하나은행',
	'SC제일은행',
	'경남은행',
	'광주은행',
	'대구은행',
	'도이치은행',
	'뱅크오브아메리카',
	'부산은행',
	'산림조합은행',
	'저축은행',
	'새마을은행',
	'수협은행',
	'신협은행',
	'우체국은행',
	'전북은행',
	'제주은행',
	'중국건설은행',
	'중국공상은행',
	'중국은행',
	'BNP은행',
	'HSBC은헹',
	'JP모건은행',
	'케이뱅크',
	'토스뱅크',
	'카카오뱅크',
	'교보증권',
	'대신증권',
	'DB증권',
	'메리츠증권',
	'미래에셋증권',
	'부국증권',
	'삼성증권',
	'신명증권',
	'신한증권',
	'SK증권',
	'현대증권',
	'유안타증권',
	'이베스트증권',
	'케이프증권',
	'키움증권',
	'한국포스증권',
	'하나증권',
	'하이투자증권',
	'한국증권',
	'한화증권',
	'KB증권',
	'다올증권',
	'BNK증권',
	'NH증권',
	'카카오페이증권',
	'IBK증권',
	'토스증권',
];
export const customsLocationTags = [
	{ location: 'Incheon T1', isActive: true },
	{ location: 'Incheon T2', isActive: false },
	{ location: 'Gimpo', isActive: false },
	{ location: 'Gimhae', isActive: false },
	{ location: 'Jeju', isActive: false },
];
export const customsLocationDetail = [
	{
		id: 1,
		location: 'Incheon T1',
		name: 'Incheon International Terminal1 - 3F',
		places: [
			{
				id: 1,
				placeDetail: 'Departure Hall - J, D',
				placeImg: Incheon1,
				refundPrintImg: Incheon1Map,
			},
		],
		isActive: true,
	},
	{
		id: 2,
		location: 'Incheon T2',
		name: 'Incheon International Terminal2 - 3F',
		places: [
			{
				id: 1,
				placeDetail: 'Departure Hall - E, D',
				placeImg: Incheon2,
				refundPrintImg: Incheon2Map,
			},
		],
		isActive: false,
	},
	{
		id: 3,
		location: 'Gimpo',
		name: 'Gimpo International Terminal - 2F',
		places: [
			{
				id: 1,
				placeDetail: 'Gate - 1, 2',
				placeImg: Gimpo,
				refundPrintImg: GimpoMap,
			},
		],
		isActive: false,
	},
	{
		id: 4,
		location: 'Gimhae',
		name: 'Gimhae International Terminal - 2F',
		places: [
			{
				id: 1,
				placeDetail: 'Gate - 3, 4',
				placeImg: Gimhae,
				refundPrintImg: GimhaeMap,
			},
		],
		isActive: false,
	},
	{
		id: 5,
		location: 'Jeju',
		name: 'Jeju International Terminal - 3F',
		places: [
			{
				id: 1,
				placeDetail: 'Gate - 5',
				placeImg: Jeju,
				refundPrintImg: JejuMap,
			},
		],
		isActive: false,
	},
];
export const guideLocations = [
	{ location: '인천 T1', isActive: true },
	{ location: '인천 T2', isActive: false },
	{ location: '김포', isActive: false },
	{ location: '김해', isActive: false },
];

export const guidePlaces = [
	{
		id: 1,
		location: '인천 T1',
		name: '인천공항 1터미널',
		places: [
			{
				id: 1,
				placeDetail: '3층 8번 출구 앞 서점 (07:00~20:00)',
				placeImg: placeImg1,
				refundPrintImg: refundPrintImg1,
			},
			{
				id: 2,
				placeDetail: '2층 5번 출구 던킨 (07:30~16:00)',
				placeImg: placeImg2,
				refundPrintImg: refundPrintImg2,
			},
			{
				id: 3,
				placeDetail: '지하 1층 5번 출구 공차 (08:00~18:00)',
				placeImg: placeImg3,
				refundPrintImg: refundPrintImg3,
			},
		],
		isActive: true,
	},
	{
		id: 2,
		location: '인천 T2',
		name: '인천공항 2터미널',
		places: [
			{
				id: 1,
				placeDetail: '3층 안내데스크 D, C 뒤',
				placeImg: placeImg4,
				refundPrintImg: refundPrintImg4,
			},
		],
		isActive: false,
	},
	{
		id: 3,
		location: '김포',
		name: '김포공항 국내선',
		places: [
			{
				id: 1,
				placeDetail: '4층 푸드코트 롯데리아 맞은편',
				placeImg: placeImg5,
				refundPrintImg: refundPrintImg5,
			},
		],
		isActive: false,
	},
	{
		id: 4,
		location: '김해',
		name: '김해공항 국제선',
		places: [
			{
				id: 1,
				placeDetail: '2층 3번 출구 안내데스크 앞',
				placeImg: placeImg6,
				refundPrintImg: refundPrintImg6,
			},
		],
		isActive: false,
	},
];
export const getStatusData = ({
	nationality,
	paymentStatus,
	unapprovedCount,
	isRefundInfoRegistered,
	isUploadRequired,
	isReceiptImageUploaded,
}: Partial<IGlobalState>) => {
	let title, description, image;
	if (isUploadRequired) {
		//4009 재외국민
		const currentStep = Boolean(
			paymentStatus === 'PAYMENT_COMPLETE' &&
				isRefundInfoRegistered &&
				isReceiptImageUploaded
		)
			? 4
			: Boolean(isRefundInfoRegistered && isReceiptImageUploaded)
			? 3
			: isRefundInfoRegistered
			? 2
			: 1;
		switch (currentStep) {
			case 1:
				title = '계좌 정보가 필요해요!';
				description = '환급을 받으시려면 계좌 정보를 입력해주세요.';
				image = EnterRefundInfo;
				break;
			case 2:
				title = 'Tax Refund 영수증 사진을 찍어주세요.';
				description = `세관 확인 도장을 받은 Tax Refund 영수증\n사진을 올려주세요.`;
				image = UploadReceipt;
				break;
			case 3:
				title = '소중한 환급액을 송금 중입니다!';
				description = '세관 확인 후 송금까지 영업일 기준\n21일 정도 소요됩니다';
				image = SendingMoney;
				break;
			case 4:
				title = '환급금을 송금했어요!';
				description = `즐거운 여행이 되셨기를 바랍니다`;
				image = Completed;
				break;
		}
	} else {
		//외국인 + 2년 이상 거주 재외국민
		const currentStep = Boolean(
			paymentStatus === 'PAYMENT_COMPLETE' &&
				unapprovedCount === 0 &&
				isRefundInfoRegistered
		)
			? 4 //즐거운 여행
			: Boolean(unapprovedCount === 0 && isRefundInfoRegistered)
			? 3 //송금 중
			: Boolean(isRefundInfoRegistered && unapprovedCount !== 0)
			? 2 //세관 방문
			: 1; //송금 정보
		switch (currentStep) {
			case 1:
				if (nationality === 'KOR') {
					title = '계좌 정보가 필요해요!';
					description = (
						<p>
							환급금을 받을 계좌 정보를{' '}
							<span style={{ color: '#1856CD' }}>3개월 이내</span>에
							<br />
							입력해주세요.
						</p>
					);
				} else {
					title = 'Enter the information.';
					description = (
						<p>
							You must enter the information
							<br />
							<span style={{ color: '#1856CD' }}>within 3 months</span> for the
							procedure
							<br />
							to receive a refund.
						</p>
					);
				}
				image = EnterRefundInfo;
				break;
			case 2:
				if (nationality === 'KOR') {
					title = '세관을 방문해주세요!';
					description = `세관에 모바일 Tax Refund 영수증과\n구매한 물건을 보여주세요.`;
				} else {
					title = 'Visit customs';
					description = `Show your purchases and\nKTP Mobile receipt to customs.`;
				}
				image = VisitCustoms;
				break;
			case 3:
				if (nationality === 'KOR') {
					title = '환급액을 송금 중입니다!';
					description =
						'텍스리펀은 세관 확인 후 송금까지\n영업일 기준 평균 21일 소요됩니다.';
				} else {
					title = `Transferring your refund`;
					description = `Tax refund will be completed about\n21 business days after customs\nconfirmation.`;
				}
				image = SendingMoney;
				break;

			case 4:
				if (nationality === 'KOR') {
					title = '환급금을 송금했어요!';
					description = `즐거운 여행 되셨길 바랍니다`;
				} else {
					title = `Remittance Completed`;
					description = `We hope you had a great trip`;
				}
				image = Completed;
				break;
		}
	}
	return { title, description, image };
};
export const qnaData = [
	{
		title: '환급액은 언제 받을 수 있나요?',
		content:
			'환급액은 출국 확인 후 송금 완료까지 영업일 기준 21일 정도\n소요됩니다.',
	},
	{
		title: '환급 절차가 어떻게 되나요?',
		content: `송금 정보 입력 → 세관 방문 → 송금\n\n앞선 절차를 진행해주셔야 환급까지 이루어질 수 있습니다.\n\n[공항 와이파이 접속 후 반출 미승인이 될 경우]\n세관 확인 대상이므로 세관 확인이 필요합니다.`,
	},
	{
		title: '절차를 완수했는데도 환급을 못 받았습니다.',
		content: `환급액은 반출 승인 후 송금 완료까지 영업일 기준 21일 정도\n소요됩니다.\n환급정보가 잘 못 기입 되었거나 반출 승인을 받지 못하셨다면\n환급이 불가합니다. 반출 승인을 받으셨다면 환급 정보를\n다시 한 번 확인 해주세요.`,
	},
	{
		title: '세관 확인을 못 받고 출국했습니다. 환급 받을 수 있나요?',
		content: '반출 승인을 받지 못하셨으면 환급이 불가합니다.',
	},
];
export const qnaDataEng = [
	{
		title: 'When will I receive my refunds?',
		content:
			'Tax refunds take an average of 21 business days.\nHowever, please understand that there may be a little\nmore delay depending on various circumstances.',
	},
	{
		title: 'How does the tax refund process work?',
		content: `Enter Information → Visit Customs → Receive Refunds\n\nEnter your information on the KTP website and show your purchases and mobile receipt to customs. After this process, we will transfer the refund amount.`,
	},
	{
		title: `I completed the process but still didn't receive the refund.`,
		content: `It takes about 21 business days for the transfer. If the\ninformation was filled out incorrectly or you didn't go to\ncustoms, then we can't refund you. If you followed the\nprocedure and didn't get your money, please double-\ncheck your information.`,
	},
	{
		title: 'I left the country without going to customs, can I get a refund?',
		content: `Unfortunately, if you didn't go to customs, you can't get a refund.`,
	},
];
