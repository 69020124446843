import styled from "styled-components";

import Calendar from "assets/home/calendar.png";
import FlexWrap from "components/_common/FlexWrap";
import { useAppDispatch, useAppSelector } from "hooks/useReduxHooks";
import { getDday } from "utils/common";

function DdayItem() {
	const { departureDate, nationality } = useAppSelector((state) => state);
	const dispatch = useAppDispatch();

	const onClick = () => {
		dispatch({
			type: "UPDATE_HOME_MODAL_STATE",
			payload: {
				isActive: true,
				type: "CALENDAR",
				data: null,
			},
		});
	};
	return (
		<Wrapper>
			<Container dir="row" alignItems="center" onClick={onClick}>
				<Icon src={Calendar} />
				<Text>
					{(nationality === "KOR" ? "출국 예정일까지 " : "Departure ") +
						getDday(departureDate)}
				</Text>
			</Container>
		</Wrapper>
	);
}
const Wrapper = styled(FlexWrap)``;
const Container = styled(FlexWrap)`
	background-color: #246cf6;
	padding: 4px 16px;
	flex-grow: 0;
	flex: 0 0 auto;
	border-radius: 100px;
	position: relative;
	cursor: pointer;
	:hover {
		opacity: 0.7;
	}
`;
const Icon = styled.img`
	width: 24px;
	height: 24px;
	margin-right: 8px;
`;
const Text = styled.div`
	color: #fff;
	font-size: 14px;
`;
export default DdayItem;
